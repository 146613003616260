body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .MuiInputBase-root, .MuiFormLabel-root {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-wrapper {
  display: flex;
  height: 100%;
}

@media screen and (max-width: 800px) {
  .page-wrapper {
    flex-direction: column;
  }
}

#scroll::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

#scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(169, 169, 169, 0.3);
  background-color: #f5f5f570;
  border-radius: 5px;
}

#scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #d0d0d0;
}