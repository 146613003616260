@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans&display=swap);

.App {
  /* font-family: Noto Sans; */
  text-align: center;
  color: #2f2f2f;
  height: 100vh;
  overflow: hidden;
}


@media screen and (max-width: 800px) {
  .App {
    /* height: 100%; */
    overflow: auto;
  }
}